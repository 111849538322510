var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('views.membershipCreate.title'))+" ")]),(_vm.division)?_c('v-form',{ref:"membershipCreateForm",attrs:{"on-submit":_vm.onSubmit,"on-submit-success":_vm.onSubmitSuccess,"on-submit-error":_vm.onSubmitError,"process-form":_vm.changeDateFormat,"initial-value":{ division: _vm.division.id }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var form = ref.form;
return [_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column is-6"},[_c('b-field',{attrs:{"label":_vm.$t('membershipCreateForm.personLabel'),"message":form.errors.person_id || form.errors.invitee_email,"type":form.types.person_id || form.types.invitee_email}},[_c('person-selector',{on:{"newMemberAdded":function($event){_vm.newMember = true}},model:{value:(form.values.person_id),callback:function ($$v) {_vm.$set(form.values, "person_id", $$v)},expression:"form.values.person_id"}})],1)],1),_c('div',{staticClass:"column is-6"},[_c('b-field',[_c('b-checkbox',{model:{value:(form.values.is_admin),callback:function ($$v) {_vm.$set(form.values, "is_admin", $$v)},expression:"form.values.is_admin"}},[_vm._v(" "+_vm._s(_vm.$t('membershipCreateForm.makeAdmin'))+" ")])],1)],1)]),(_vm.newMember)?_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column is-6"},[_c('b-field',{attrs:{"label":_vm.$t('membershipCreateForm.membershipTypeLabel'),"message":form.errors.membership_type,"type":form.types.membership_type}},[_c('membership-type-selector',{model:{value:(form.values.membership_type_id),callback:function ($$v) {_vm.$set(form.values, "membership_type_id", $$v)},expression:"form.values.membership_type_id"}})],1)],1)]):_vm._e(),(_vm.newMember)?_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column is-6"},[_c('b-field',{attrs:{"label":_vm.$t('membershipCreateForm.membershipStartDateLabel'),"message":form.errors.membership_start_date,"type":form.types.membership_start_date}},[_c('b-datepicker',{attrs:{"placeholder":_vm.$t('form.clickToSelect'),"value":form.values.membership_start_date
                  ? _vm.dateWithoutOffset(form.values.membership_start_date)
                  : _vm.dateWithoutOffset(new Date())},on:{"input":function (value) {
                  form.values.membership_start_date = value
                }},model:{value:(_vm.membership_start_date),callback:function ($$v) {_vm.membership_start_date=$$v},expression:"membership_start_date"}})],1)],1)]):_vm._e(),_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column is-6"},[_c('b-field',{attrs:{"label":_vm.division.is_project
                ? _vm.$t('membershipCreateForm.projectRoleTypeLabel')
                : _vm.$t('membershipCreateForm.divisionRoleTypeLabel'),"message":form.errors.membership_type,"type":form.types.membership_type}},[_c('role-type-selector',{model:{value:(form.values.role_type_id),callback:function ($$v) {_vm.$set(form.values, "role_type_id", $$v)},expression:"form.values.role_type_id"}})],1)],1)]),_c('b-field',{attrs:{"label":_vm.$t('membershipCreateForm.divisionLabel')}},[_c('div',{staticClass:"control"},[_vm._v(" "+_vm._s(_vm.$data.division.name)+" ")])]),_c('b-button',{attrs:{"native-type":"submit"}},[_vm._v(_vm._s(_vm.$t('membershipCreateForm.button')))])]}}],null,false,2082211671)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }